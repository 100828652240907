import React, { useState, useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { HiMiniUserCircle } from "react-icons/hi2";

import MainHeader from "./MainHeader";
import NavLinks from "./NavLinks";
import SideDrawer from "./SideDrawer";
import Backdrop from "../UIElements/Backdrop";
import icon from "../../../images/meadowlark_icon.png";
import { AuthContext } from "../../context/auth-context";

import "./MainNavigation.css";

export default function MainNavigation() {
  let auth = useContext(AuthContext);
  const [drawerIsOpen, setDrawerIsOpen] = useState(false);

  const location = useLocation();

  function openDrawerHandler() {
    setDrawerIsOpen(true);
  }

  function closeDrawerHandler() {
    setDrawerIsOpen(false);
  }

  return (
    <>
      {drawerIsOpen && <Backdrop onClick={closeDrawerHandler} />}
      <SideDrawer show={drawerIsOpen} onClick={closeDrawerHandler}>
        <nav className="main-navigation__drawer-nav">
          <NavLinks closeDrawer={closeDrawerHandler} />
        </nav>
      </SideDrawer>

      {location.pathname !== "/" && !location.pathname.endsWith("stats") && (
        <div className="banner"></div>
      )}

      <MainHeader>
        <button
          className="main-navigation__menu-btn"
          onClick={openDrawerHandler}
        >
          <span />
          <span />
          <span />
        </button>
        <Link
          to="/"
          style={{
            textDecoration: "none",
          }}
        >
          <div className="main-navigation-logo-container">
            {/* <FaBinoculars color="white" size={50} style={{ margin: "5px" }} /> */}
            <img
              className="meadowlark-icon"
              src={icon}
              alt="Meadowlark Binoculars Icon"
            />
            <h1 className="main-navigation__title">Meadowlark</h1>
          </div>
        </Link>
        <div className="left-space"></div>
        <nav className="main-navigation__header-nav">
          <NavLinks closeDrawer={closeDrawerHandler} />
        </nav>
        <div className="right-space"></div>
        <div className="user-tooltip tooltip-container">
          <div className="tooltip-text">
            {auth.userId
              ? `Welcome ${
                  auth.userName.length > 14
                    ? auth.userName.slice(0, 14) + "..."
                    : auth.userName
                }!`
              : "No signed-in user."}
          </div>
          <HiMiniUserCircle
            color={auth.userId ? "paleturquoise" : "gray"}
            size={45}
            className="user-icon"
          />
        </div>{" "}
      </MainHeader>
    </>
  );
}
