import React, { useState, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import Modal from "../UIElements/Modal";
import Button from "../FormElements/Button";
import LoadingSpinner from "../UIElements/LoadingSpinner";
import ErrorModal from "../UIElements/ErrorModal";
import { AuthContext } from "../../context/auth-context";
import { useHttpClient } from "../../hooks/http-hook";

import "./NavLinks.css";

export default function NavLinks(props) {
  const auth = useContext(AuthContext);
  const [showGuestModal, setShowGuestModal] = useState(false);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const navigate = useNavigate();

  async function confirmGuestLoginHandler() {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/users/signup",
        "POST",
        JSON.stringify({
          name: `guest-user--${Math.trunc(
            Math.random() * 100000000000000000000
          )}`,
          email: `${Math.trunc(
            Math.random() * 100000000000000000000
          )}@${Math.trunc(Math.random() * 100000000000000000000)}.com`,
          password: "GUEST-PASSWORD",
        }),
        {
          "Content-Type": "application/json",
        }
      );

      auth.login(responseData.userId, responseData.token, "Guest");
      toast.success(`Welcome Guest!`);
      setShowGuestModal(false);
      props.closeDrawer();
      navigate("/locations");
    } catch (err) {}
  }

  function handleLogout() {
    auth.logout();
    toast.success("Successfully logged out.");
    navigate("/locations");
  }

  return (
    <>
      {isLoading && <LoadingSpinner asOverlay />}
      <ErrorModal error={error} onClear={clearError} />

      <Modal
        show={showGuestModal}
        onCancel={() => setShowGuestModal(false)}
        header="Guest Login"
        footer={
          <>
            <Button
              onClick={(event) => {
                event.stopPropagation();
                confirmGuestLoginHandler();
              }}
            >
              SIGN IN AS GUEST
            </Button>
            <Button inverse onClick={() => setShowGuestModal(false)}>
              CANCEL
            </Button>
          </>
        }
      >
        <h2> Welcome to Meadowlark!</h2>
        <p>
          As a guest, you can explore the site in a safe sandbox environment -
          you are free to add, view, edit, or delete any hot spots or sightings.
        </p>
        <p>If you do wish to save your data permanently, create an account.</p>
      </Modal>
      <ul className="nav-links">
        <li>
          <NavLink className="no-wrap" to="/locations" exact>
            COMMUNITY SIGHTINGS
          </NavLink>
        </li>
        {auth.isLoggedIn && (
          <li>
            <NavLink className="no-wrap" to={`/${auth.userId}/locations`}>
              MY SIGHTINGS
            </NavLink>
          </li>
        )}
        {auth.isLoggedIn && (
          <li>
            <NavLink className="no-wrap" to="/location/new">
              ADD LOCATION
            </NavLink>
          </li>
        )}
        {auth.isLoggedIn && (
          <li>
            <NavLink className="no-wrap" to={`/${auth.userId}/stats`}>
              MY STATS
            </NavLink>
          </li>
        )}
        {!auth.isLoggedIn && (
          <li>
            <NavLink className="no-wrap" to="/auth">
              LOGIN
            </NavLink>
          </li>
        )}
        {!auth.isLoggedIn && (
          <li>
            <button
              className="no-wrap"
              inverse
              onClick={(event) => {
                event.stopPropagation();
                setShowGuestModal(true);
              }}
            >
              GUEST MODE
            </button>
          </li>
        )}
        {auth.isLoggedIn && (
          <li>
            <button className="no-wrap" inverse onClick={handleLogout}>
              LOGOUT
            </button>
          </li>
        )}
      </ul>
    </>
  );
}
