import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import YoutubeEmbed from "../shared/components/UIElements/YoutubeEmbed";

import community from "../images/community.png";
import viewlocation from "../images/view-location.png";
import mysightings from "../images/my-sightings.png";
import addlocation from "../images/add-location.png";
import mystats from "../images/my-stats.png";
import Button from "../shared/components/FormElements/Button";
import { useHttpClient } from "../shared/hooks/http-hook";
import { AuthContext } from "../shared/context/auth-context";

import "./Landing.css";

export default function Landing() {
  const auth = useContext(AuthContext);
  const { sendRequest } = useHttpClient();
  const navigate = useNavigate();

  async function confirmGuestLoginHandler() {
    try {
      const responseData = await sendRequest(
        process.env.REACT_APP_BACKEND_URL + "/users/signup",
        "POST",
        JSON.stringify({
          name: `guest-user--${Math.trunc(
            Math.random() * 100000000000000000000
          )}`,
          email: `${Math.trunc(
            Math.random() * 100000000000000000000
          )}@${Math.trunc(Math.random() * 100000000000000000000)}.com`,
          password: "GUEST-PASSWORD",
        }),
        {
          "Content-Type": "application/json",
        }
      );

      auth.login(responseData.userId, responseData.token, "Guest");
      toast.success(`Welcome Guest!`);
      navigate("/locations");
    } catch (err) {}
  }

  return (
    <>
      <div className="background">
        <div className="translucent-box">
          <p>Start your birding adventure with Meadowlark</p>
        </div>
      </div>
      <div className="landing-info-container-purple">
        <div className="video-container">
          <YoutubeEmbed embedId="429vVZc4nS0" />
          <p className="demo-video-label">
            Watch our demo to discover the many features of Meadowlark
          </p>
        </div>
        <div className="landing-info-description-purple">
          <h2>Explore</h2>
          <p>
            Find local hot spots, discover trends, and manage your North
            American bird sightings
          </p>
        </div>
      </div>
      <div className="landing-info-container-grey">
        <img
          className="landing-info-image-grey"
          src={community}
          alt="Screenshot of Community Sightings page"
        />
        <div className="landing-info-description-grey">
          <h2>Filter</h2>
          <p>
            Filter our database of Community Sightings to locate specific
            species in your area
          </p>
          <Button to="locations">Community Sightings</Button>
        </div>
      </div>
      <div className="landing-info-container-purple">
        <img
          className="landing-info-image-purple"
          src={viewlocation}
          alt="Screenshot of View Location page"
        />

        <div className="landing-info-description-purple">
          <h2>Experience our app in Guest Mode</h2>
          <p>
            You can view, add, edit, and delete hots spots and sightings in a
            safe sandbox environment
          </p>
          <Button onClick={confirmGuestLoginHandler}>Try Guest Mode</Button>
        </div>
      </div>{" "}
      <div className="landing-info-container-grey">
        <img
          className="landing-info-image-grey"
          src={mysightings}
          alt="Screenshot of My Sightings page"
        />
        <div className="landing-info-description-grey">
          <h2>My Sightings</h2>
          <p>
            View and filter your own sightings, organized by location, on the My
            Sightings map. Sign up to get started
          </p>
          <Button to={auth.userId ? `${auth.userId}/locations` : "auth"}>
            Sign up
          </Button>
        </div>
      </div>
      <div className="landing-info-container-purple">
        <img
          className="landing-info-image-purple"
          src={addlocation}
          alt="Screenshot of Add Location page"
        />
        <div className="landing-info-description-purple">
          <h2>Be the first to add a new Hot Spot</h2>
          <p>
            Add new birding hot spots to the database using the Add Location
            feature
          </p>
          <Button to={auth.userId ? "location/new" : "auth"}>
            Add a Hot Spot
          </Button>
        </div>
      </div>
      <div className="landing-info-container-grey">
        <img
          className="landing-info-image-grey"
          src={mystats}
          alt="Screenshot of My Stats page"
        />
        <div className="landing-info-description-grey">
          <h2>Stay organized</h2>
          <p>
            Search, organize, and analyze your sighting data using the My Stats
            page
          </p>{" "}
          <Button to={auth.userId ? `/${auth.userId}/stats` : "auth"}>
            See Personal Stats
          </Button>
        </div>
      </div>
    </>
  );
}
